import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import ErrorBoundary from './toolympus/components/app/ErrorBoundary';
import AppRouter from './AppRouter';
import "./index.css";
import { Providers } from './Providers';
import { LoginContainer, SignupContainer, ResetPasswordContainer, RestorePasswordContainer } from './components/Auth';
import { EmployeeLoginToQuestionPage } from './events/Pages/EmployeeLoginToQuestionPage';
import { GlobalStyles } from './GlobalStyles';

const App: React.FC = () => {
    return (
        <Providers>
            <Router>
                <ErrorBoundary>
                    <GlobalStyles />
                    <Switch>
                        <Route path='/signup' component={SignupContainer} />
                        <Route path='/login' component={LoginContainer} />
                        <Route path='/reset' component={ResetPasswordContainer} />
                        <Route path='/restore-password' component={RestorePasswordContainer} />
                        <Route exact path={"/employee/question/:id"} component={EmployeeLoginToQuestionPage} />
                        <Route path='/' component={AppRouter} />
                    </Switch>
                </ErrorBoundary>
            </Router>
        </Providers>
    );
}

export default App;